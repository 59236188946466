<script setup>
import Banner from "@/Components/Banner.vue";
import SecondaryLink from "@/Components/Link/SecondaryLink.vue";
import {Head, Link, useForm} from '@inertiajs/inertia-vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Input/Checkbox.vue';
import InputError from '@/Components/Input/InputError.vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import PrimaryButton from '@/Components/Input/PrimaryButton.vue';
import TextInput from '@/Components/Input/TextInput.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const codeForm = useForm({
    login_code: '',
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
}

const submitLoginCode = () => {
    codeForm.post(route('hardware-login.login_code', { login_code: codeForm.login_code }), {
        onFinish: () => {
                codeForm.reset('login_code')
        },
        preserveScroll: true
    });
};
</script>

<template>
    <Head title="Log in"/>

    <Banner />


    <div class="bg-gray-100 dark:bg-gray-900 grid gap-2" :class="{ 'sm:grid-cols-2': $page.props.location_is_secure }">


    <AuthenticationCard>
        <template #logo>
            <div class="text-center">

                <AuthenticationCardLogo/>
                <span class="font-montserrat font-bold text-2xl text-pink-600">ERP</span>
            </div>

        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" value="Email"/>
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    :autofocus="!$page.props.location_is_secure"
                />
                <InputError class="mt-2" :message="form.errors.email"/>
            </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password"/>
                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="current-password"
                />
                <InputError class="mt-2" :message="form.errors.password"/>
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember"/>
                    <span class="ml-2 text-sm text-gray-600 dark:text-gray-300">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link v-if="canResetPassword" :href="route('password.request')"
                      class="underline text-sm text-gray-600 hover:text-gray-900 dark:text-gray-400">
                    Forgot your password?
                </Link>

                <SecondaryLink class="ml-4" :class="{ 'opacity-25': form.processing }" :href="route('auth.cloud.redirect')">
                    Cloud Login
                </SecondaryLink>

                <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Log in
                </PrimaryButton>
            </div>
        </form>

    </AuthenticationCard>

        <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0">

        <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg dark:bg-gray-800" v-if="$page.props.location_is_secure">
            <form @submit.prevent="submitLoginCode">

                <div class="mt-4">
                    <InputLabel for="login_code" value="Login Code"/>
                    <TextInput
                        id="login_code"
                        v-model="codeForm.login_code"
                        type="password"
                        class="mt-1 block w-full"
                        required
                        :autofocus="$page.props.location_is_secure"
                    />
                    <InputError class="mt-2" :message="codeForm.errors.login_code"/>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <PrimaryButton class="ml-4" :class="{ 'opacity-25': codeForm.processing }" :disabled="codeForm.processing">
                        Log in
                    </PrimaryButton>


                </div>
            </form>
        </div>
    </div>
    </div>




</template>
